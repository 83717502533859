<template>
  <div class="product-wrap programme-farm">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-farm"></div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="txt-box align-ct no-indent pd-5">
          <p>智慧农业就是将物联网技术运用到传统农业中去，运用传感器和软件通过移动平台或者电脑平台对农业生产进行控制，使传统农业更具有“智慧”。</p>
          <p>除了精准感知、控制与决策管理外，从广泛意义上讲，智慧农业还包括农业电子商务、食品溯源防伪、农业休闲旅游、农业信息服务等方面的内容。</p>
          <p>智慧农业是农业生产的高级阶段，是集新兴的互联网、移动互联网、云计算和物联网技术为一体，</p>
          <p>依托部署在农业生产现场的各种传感节点（环境温湿度、土壤水分、二氧化碳、图像等）和无线通信网络实现农业生产环境的智能感知、</p>
          <p>智能预警、智能决策、智能分析、专家在线指导，</p>
          <p>为农业生产提供精准化种植、可视化管理、智能化决策。</p>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-green align-ct"><h4>行业痛点</h4></div>
        <div class="pain-box mt-2">
          <div class="td-item">
            <img src="../../assets/img/programme/farm/td_a.jpg" alt="工作效率低">
            <p class="color-59">工作效率低</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/farm/td_b.jpg" alt="劳动力欠缺">
            <p class="color-59">劳动力欠缺</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/farm/td_c.jpg" alt="技术手段落后">
            <p class="color-59">技术手段落后</p>
          </div>
          <div class="td-item">
            <img src="../../assets/img/programme/farm/td_d.jpg" alt="收益偏低">
            <p class="color-59">收益偏低</p>
          </div>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big color-green align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box">
          智慧农业管理平台利用视频直播溯源技术、区块链防篡改溯源技术、物联网环境监测传感技术，智能虫情监测技术、无人机巡航技术、智能灌溉技术、大数据分析技术构建的综合管理系统。
          主要包括产品追溯系统、智能灌溉、TC营销系统、大数据分析展示系统、物联网中心、园区信息化管理系统。
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-green align-ct"><h4>方案架构</h4></div>
        <div class="auto-img mt-2">
          <img src="../../assets/img/product/farm/pic_1.png" alt="方案架构">
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big color-green align-ct"><h4>方案优势</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-zhuisu"></i></div>
            <p>产品的全流程追溯</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-zhongzhi"></i></div>
            <p>标准种植行为管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-hjjc"></i></div>
            <p>生长环境监测</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-SOP"></i></div>
            <p>SOP管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-guangai"></i></div>
            <p>智能灌溉</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont color-green icon-baobiao"></i></div>
            <p>大数据分析</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-farm {
  .banner-farm {
    background: url("../../assets/img/programme/farm/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }
}

</style>
